const burgerMenu = document.querySelector('.burger-menu');
const trigger = document.querySelector('.header__burger');
const body = document.querySelector('body');
const header = document.querySelector('header');

const accordionItems = document.querySelectorAll('.burger-menu .header__nav-item');
const subMenues = document.querySelectorAll('.burger-menu .header__sub');
const accordionItemsLinks = document.querySelectorAll('.burger-menu .header__nav-link');

const openBurgerMenu = () => {
    const paddingOffset = `${window.innerWidth - document.body.offsetWidth}px`;

    burgerMenu.classList.add('-js-visible');
    header.classList.add('-js-burger-open');
    trigger.classList.add('-js-close-burger');
    body.style.overflow = 'hidden';
    body.style.paddingRight = paddingOffset;
    header.style.paddingRight = paddingOffset;
};

const closeBurgerMenu = () => {
    burgerMenu.classList.remove('-js-visible');
    trigger.classList.remove('-js-close-burger');

    accordionItemsLinks.forEach(link => {
        link.classList.remove('-js-menu-showed');
    });

    subMenues.forEach(menu => {
        menu.classList.remove('-js-visible');
    });

    setTimeout(() => {
        body.style.overflow = 'visible';
        body.style.paddingRight = 0;
    }, 400);
};

const initAccordion = () => {
    accordionItems.forEach(item => {
        const subMenu = item.querySelector('.header__sub');
        const itemLink = item.querySelector('a');

        if (subMenu) {
            itemLink.addEventListener('click', (e) => {
                e.preventDefault();
                const currentTarget = e.currentTarget;
                subMenu.classList.toggle('-js-visible');
                currentTarget.classList.toggle('-js-menu-showed');
            });
        }
    });
};

document.addEventListener('click', (e) => {
    const target = e.target;
    const withinBoundaries = e.composedPath().includes(burgerMenu);

    if (target.closest('.header__burger')) {
        burgerMenu.classList.contains('-js-visible') ? closeBurgerMenu() : openBurgerMenu();
    } else if (!withinBoundaries && burgerMenu.classList.contains('-js-visible')) {
        closeBurgerMenu();
    }
});

window.innerWidth <= 1024 ? initAccordion() : '';
