const rightSlider = document.querySelector('.grid-sliders__right-slider');
const leftSlider = document.querySelector('.grid-sliders__left-slider');

const initSliders = () => {

    new Swiper('.grid-sliders__right-slider', {

        direction: 'horizontal',
        
        loop: true,
        rewind: true,

        slidesPerView: 1,
        spaceBetween: 24,

        navigation: {
          nextEl: '.grid-sliders__right-slider-arrow--next',
          prevEl: '.grid-sliders__right-slider-arrow--prev',
        },

        pagination: {
          el: '.grid-sliders__right-slider .swiper-pagination',
        },

    });

    new Swiper('.grid-sliders__left-slider', {

        direction: 'horizontal',
        
        loop: true,
        rewind: true,

        slidesPerView: 1,
        spaceBetween: 24,

        navigation: {
          nextEl: '.grid-sliders__left-slider-arrow--next',
          prevEl: '.grid-sliders__left-slider-arrow--prev',
        },

        pagination: {
          el: '.grid-sliders__left-slider .swiper-pagination',
        },

    });

}

rightSlider && leftSlider ? initSliders() : '';