const slider = document.querySelector('.stocks__slider');

const initSlider = () => {

    new Swiper('.stocks__slider', {

        direction: 'horizontal',
        
        loop: true,
        rewind: true,

        slidesPerView: 1,
        spaceBetween: 20,

        navigation: {
          nextEl: '.stocks__arrow--next',
          prevEl: '.stocks__arrow--prev',
        },

        breakpoints: {
            768: {
                slidesPerView: 2,
            },

            1024: {
                slidesPerView: 2,
                spaceBetween: 24
            }


        }

    });

}

slider ? initSlider() : '';