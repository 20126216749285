let galleryTop = new Swiper('.hero__main', {
    loop: true,

    spaceBetween: 15,

    navigation: {
        prevEl: '.hero__main-button--prev',
        nextEl: '.hero__main-button--next',
    },

    pagination: {
        el: '.hero__pagination',
        type: 'bullets',
    },

});

let galleryThumbs = new Swiper('.hero__thumbs', {
    slidesPerView: 4,
    spaceBetween: 16,
    
    slideToClickedSlide: true,
    loop: true,
});

galleryTop.controller.control = galleryThumbs;
galleryThumbs.controller.control = galleryTop;
