const buttons = document.querySelectorAll('.services-tabs__button');
const content = document.querySelectorAll('.services-tabs__item');


if (buttons) {
    
        buttons.forEach(el => {
    
            el.addEventListener('click', (e) => {
    
                const buttonDataAttribute = el.getAttribute('data-tab')
    
                buttons.forEach(el => {
                    el.classList.remove('-js-active');
                });
    
                el.classList.add('-js-active');
                
                if (content) {

                    content.forEach(el => {
        
                        const contentDataAttribute = el.getAttribute('data-tab')
        
                        el.classList.remove('-js-active');
        
                        if (buttonDataAttribute == contentDataAttribute) {
                            el.classList.add('-js-active');
                        };
                    });
                }

    
            });
        });

}

