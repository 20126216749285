import "%modules%/header/header";
import "%modules%/sertificates/sertificates";
import "%modules%/gallery/gallery";
import "%modules%/doctors/doctors";
import "%modules%/stocks/stocks";
import "%modules%/stocks-hero/stocks-hero";
import "%modules%/hero/hero";
import "%modules%/grid-sliders/grid-sliders";
import "%modules%/services-slider/services-slider";
import "%modules%/service/service";
import "%modules%/services-tabs/services-tabs";
import "%modules%/contacts-map/contacts-map";
import "%modules%/doctors-section/doctors-section";
import "%modules%/doctors-slider/doctors-slider";
import "%modules%/reviews-slider/reviews-slider";
import "%modules%/textpage/textpage";
import "%modules%/main-slider/main-slider";
import "%modules%/services-list/services-list";