// const fixHeader = () => {

//     const header = document.querySelector('header');
//     const headerHeight = header.clientHeight;
    
//     let scrollY = window.scrollY;

//     if (scrollY > 150) {

//         header.classList.add('-js-fixed');
//         document.body.style.paddingTop = `${headerHeight}px`;

//     } else {

//         header.classList.remove('-js-fixed');
//         document.body.style.paddingTop = `${0}px`;
//     }; 

// };

// window.addEventListener('scroll', fixHeader);
$(document).ready(function() {
    // Клонируем блок, который нужно скопировать
    var clonedLogo = $('.logo').clone();
    var clonedBlock = $('.header__nav-new').clone();
    var clonedContact = $('.header__contact-clone').clone();
    var clonedBtn = $('.header__button').clone();
    var clonedBurger = $('.header__burger').clone();
    // Вставляем скопированный блок после блока с классом header__top
    clonedLogo.appendTo('.header-fixed .header__top');
    clonedBlock.insertAfter('.header-fixed .logo');
    clonedContact.insertAfter('.header-fixed .header__nav-new');
    clonedBtn.insertAfter('.header-fixed .header__contact-clone');
    clonedBurger.insertBefore('.header-fixed .logo');

    $(window).on('scroll',function () {
        if ($(this).scrollTop() >= 180) {
            $('.header-fixed').addClass('-js-scroll');
        } else {
            $('.header-fixed').removeClass('-js-scroll');
        }
    }).trigger('scroll');

});