const slider = document.querySelector('.gallery__slider');

const initSlider = () => {

    new Swiper('.gallery__slider', {

        direction: 'horizontal',
        loop: true,
        rewind: true,
        centeredSlides: false,
        slidesPerView: 2,
        spaceBetween: 24,

        navigation: {
          nextEl: '.gallery__button--next',
          prevEl: '.gallery__button--prev',
        },
        pagination: {
            el: '.gallery__pagination',
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            280: {
                slidesPerView: 1
            },
            576: {
                slidesPerView: 2
            },
            1440: {
                slidesPerView: 2,
            },
        }
    });
    
}

slider ? initSlider() : '';

Fancybox.bind(".gallery__slide-image", { });