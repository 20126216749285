const slider = document.querySelector('.doctors-slider__swiper')

const initSlider = (slider) => {

    new Swiper(slider, {
  
      direction: 'horizontal',
  
      loop: true,
      rewind: true,
  
      slidesPerView: 4,
      spaceBetween: 24,
  
      navigation: {
        nextEl: `.doctors-slider__arrow--next`,
        prevEl: `.doctors-slider__arrow--prev`,
      },

      breakpoints: {

        320: {
          slidesPerView: 1.4,
          spaceBetween: 25
        },

        768: {
          slidesPerView: 2.8,
          spaceBetween: 25
        },

        992: {
          slidesPerView: 4,
          spaceBetween: 24
        }
      }
  
    });
  
};

slider ? initSlider(slider) : '';