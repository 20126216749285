try {
    
    const burgerMenu = document.querySelector('.burger-menu');
    const burgerMenuButton = document.querySelector('.header__burger');

    function closeModal() {

        const modals = document.querySelectorAll('.modal');
        const header = document.querySelector('header');
        const body = document.querySelector('body');

        setTimeout(() => {

            modals.forEach(modal => {

                if (modal.classList.contains('visible')) {

                    const overlay = modal.querySelector('.modal__overlay');
                    const form = modal.querySelector('form');

                    modal.classList.remove('visible');

                    body.style.overflow = 'visible';
                    body.style.paddingRight = 0;
                    overlay.style.paddingRight = 0;

                    header.style.paddingRight = 0;

                    form ? form.reset() : '';

                };

            });

        }, 200);
    };

    function openModal(obj) {

        const modal = document.querySelector(obj.modal);
        const overlay = modal.querySelector('.modal__overlay');
        const header = document.querySelector('header');

        const paddingOffset = `${window.innerWidth - document.body.offsetWidth}px`;

        if (burgerMenu.classList.contains('-js-visible')) {
            burgerMenu.classList.remove('-js-visible')
            burgerMenuButton.classList.remove('-js-close-burger')
        }

        modal.classList.add('visible');
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = paddingOffset;
        overlay.style.paddingRight = paddingOffset;

        header.style.paddingRight = paddingOffset;
    };

    function toggleModal(e) {

        const target = e.target;

        target.closest('.-js-order-modal') ? openModal({
            modal: '.order-modal',
        }) : '';

        target.closest('.-js-contact-modal') ? openModal({
            modal: '.contact-modal',
        }) : '';

        target.closest('.-js-question-modal') ? openModal({
            modal: '.question-modal',
        }) : '';

        target.closest('.-js-review-modal') ? openModal({
            modal: '.review-modal',
        }) : '';

        target.closest('.modal__close-button') || target.classList.contains('modal__overlay') ? closeModal() : '';
    };

    document.addEventListener('click', toggleModal);

} catch (error) { console.log('error in modal logic'); }
