const slider = document.querySelector('.services-slider__slider');

const initSlider = () => {

    new Swiper('.services-slider__slider', {

        direction: 'horizontal',
        
        loop: true,
        rewind: true,

        slidesPerView: 1,
        spaceBetween: 24,

        navigation: {
          nextEl: '.services-slider__slider-button--next',
          prevEl: '.services-slider__slider-button--prev',
        },

        pagination: {
          el: '.services-slider__pagination',
          type: 'bullets',
        },

    });

}

slider ? initSlider() : '';