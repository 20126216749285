const manageButtons = document.querySelectorAll('.doctors__manage-button');
const sliders = document.querySelectorAll('.doctors__slider[data-slider]');
const sliderArrows = document.querySelectorAll('.slider__button-doctors');

const setDefaultSlider = () => {

  new Swiper(`.doctors__slider[data-slider=slider-1]`, {

    direction: 'horizontal',

    loop: true,
    rewind: true,

    navigation: {
      nextEl: '.doctors__slider-arrow--next[data-slider=slider-1]',
      prevEl: '.doctors__slider-arrow--prev[data-slider=slider-1]',
    },

    breakpoints: {
      280: {
        slidesPerView: 1,
        spaceBetween: 24
      },

      576: {
        slidesPerView: 1,
        spaceBetween: 24
      },

      768: {
        slidesPerView: 1,
        spaceBetween: 24
      },

      992: {
        slidesPerView: 2,
        spaceBetween: 24
      }

    }

  });

};

const initSlider = (slider) => {

  const sliderInitializeData = slider.getAttribute('data-slider');

  sliders.forEach(slider => {
    slider.classList.remove('-js-active')
  });

  slider.classList.add('-js-active');

  sliderArrows.forEach(arrow => {
    arrow.setAttribute('data-slider', sliderInitializeData)
  });

  new Swiper(`.doctors__slider[data-slider=${sliderInitializeData}]`, {

    direction: 'horizontal',

    loop: true,
    rewind: true,

    slidesPerView: 2,
    spaceBetween: 24,

    navigation: {
      nextEl: `.doctors__slider-arrow--next[data-slider=${sliderInitializeData}]`,
      prevEl: `.doctors__slider-arrow--prev[data-slider=${sliderInitializeData}]`,
    },
    breakpoints: {
      280: {
        slidesPerView: 1,
        spaceBetween: 24
      },

      576: {
        slidesPerView: 1,
        spaceBetween: 24
      },

      768: {
        slidesPerView: 1,
        spaceBetween: 24
      },

      992: {
        slidesPerView: 2,
        spaceBetween: 24
      }

    }

  });

};

if (manageButtons) {

  manageButtons.forEach(button => {

    button.addEventListener('click', (e) => {

      const currentTarget = e.currentTarget;
      const dataAttrValue = currentTarget.getAttribute('data-slider');
      const currentSlider = document.querySelector(`.doctors__slider[data-slider=${dataAttrValue}]`);

      manageButtons.forEach(button => {
        button.classList.remove('-js-active')
      });

      currentTarget.classList.add('-js-active');

      currentSlider ? initSlider(currentSlider) : '';

    });

  });

}
;


setDefaultSlider();
