const slider = document.querySelector('.reviews-slider__swiper');

const initSlider = () => {

    new Swiper('.reviews-slider__swiper', {

        direction: 'horizontal',
        
        loop: true,
        rewind: true,
        centeredSlides: false,

        slidesPerView: 1,
        spaceBetween: 24,

        pagination: {
            el: '.reviews-slider__pagination ',
            type: 'bullets'
        },

        navigation: {
          nextEl: '.reviews-slider__button--next',
          prevEl: '.reviews-slider__button--prev',
        },

        // breakpoints: {

        //     280: {
        //         slidesPerView: 1
        //     },

        //     576: {
        //         slidesPerView: 1.2,
        //         centeredSlides: true
        //     },

        //     1024: {
        //         slidesPerView: 1.3,
        //         centeredSlides: true
        //     },

        //     1440: {
        //         slidesPerView: 2,
        //         centeredSlides: true
        //     },
        // }
    });

}

slider ? initSlider() : '';
