const sliderMain = document.querySelector('.main-slider__swiper');

const initSlider = () => {

    new Swiper('.main-slider__swiper', {

        direction: 'horizontal',
        loop: true,
        rewind: true,
        centeredSlides: false,
        slidesPerView: 2,
        spaceBetween: 24,
        effect: 'fade',
        navigation: {
          nextEl: '.main__button--next',
          prevEl: '.main__button--prev',
        },
        pagination: {
            el: '.main__pagination',
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            280: {
                slidesPerView: 1
            },
            576: {
                slidesPerView: 1
            },
            1440: {
                slidesPerView: 1,
            },
        }
    });
    
}

sliderMain ? initSlider() : '';
