$(document).ready(function() {
    let swiperServices = new Swiper('.js-slider', {
        slidesPerView: 1,
        loop: true,
        spaceBetween: 20,
        autoHeight: true,
        navigation: {
            nextEl: '.section-services__arrow--next',
            prevEl: '.section-services__arrow--prev',
        },
    });

    function chengeResize() {
        if ($(window).width() <= 575) {
            if (!swiperServices) {
                swiperServices = new Swiper('.js-slider', {
                    slidesPerView: 1,
                    loop: true,
                    autoHeight: true,
                    spaceBetween: 20,
                    navigation: {
                        nextEl: '.section-services__arrow--next',
                        prevEl: '.section-services__arrow--prev',
                    },
                });
            }
        } else {
            if (swiperServices) {
                swiperServices.destroy();
                swiperServices = null;
            }
        }
    }

    chengeResize();

    $(window).resize(function() {
        chengeResize();
    });
});
