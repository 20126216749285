const slider = document.querySelector('.doctors-list__slider');
let mql = window.matchMedia("(max-width: 768px)");

if (slider) {

  mql.addEventListener("change", (e) => {
    let newSwiper = null;
    if (e.matches) {
      
      newSwiper = new Swiper(".doctors-list__slider", {
        slidesPerView: 2,
        centeredSlides: false,
        spaceBetween: 8,
        grabCursor: true,
        init: true,
      });
      
  
    } else {

      newSwiper.destroy();

    }

  })


}

