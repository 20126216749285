const map = document.querySelector('#contacts-map');

const initContactsMap = () => {

    const manageButtons = document.querySelectorAll('.contacts-map__block-button');

    const initialCenter = [51.64967257229446,39.17244849999995];

    const data = [
        {
            center: [51.64967257229446,39.17244849999995]
        },
        {
            center: [51.71228407225714,39.14801399999999]
        },
    ];
    
    const init = () => {

        let map = new ymaps.Map('contacts-map', {
            center: initialCenter,
            zoom: 17
        });


        const createPlacemark = (center) => {

            return new ymaps.Placemark(center, {}, {
                iconLayout: 'default#image',
                iconImageHref: '../img/icons/placemark.svg',
                iconImageSize: [32, 48],
                iconImageOffset: [-19, -44]
            });
    
        };

        const addPlacemarksToMap = () => {

            data.forEach(item => {

                const newPlacemark = createPlacemark(item.center);
                map.geoObjects.add(newPlacemark);
                
            });

        };

        const setLocation = (e) => {

            const currentTarget = e.currentTarget;
            const dataCenter = currentTarget.getAttribute('data-center').split(',');
            
            map.setCenter(dataCenter);
    
        };

    
        map.controls.remove('geolocationControl');
        map.controls.remove('searchControl');
        map.controls.remove('trafficControl');
        map.controls.remove('typeSelector');
        map.controls.remove('fullscreenControl');
        map.controls.remove('zoomControl');
        map.controls.remove('rulerControl');
        map.behaviors.disable(['scrollZoom']); 


        manageButtons ? manageButtons.forEach(button => {  button.addEventListener('click', setLocation); }) : '';

        addPlacemarksToMap();
    };
    
    ymaps.ready(init);

};

map ? initContactsMap() : '';